var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return " <div class=\"chan "
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + " chan-"
    + alias2(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/slugify.js")).call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-target=\"#chan-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" role=\"tab\" aria-label=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" aria-controls=\"chan-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" aria-selected=\"false\"> "
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"lobby",{"name":"equal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/notEqual.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"lobby",{"name":"notEqual","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </div> "
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"lobby",{"name":"equal","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return " <button class=\"collapse-network\" aria-label=\"Collapse\" aria-controls=\"network-"
    + alias2(alias1((depths[1] != null ? depths[1].uuid : depths[1]), depth0))
    + "\" aria-expanded=\"true\"><span class=\"collapse-network-icon\"></span></button><div class=\"lobby-wrap\"><span class=\"name\" title=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</span><span class=\"not-secure-tooltip tooltipped tooltipped-w\" aria-label=\"Insecure connection\"><span class=\"not-secure-icon\"></span> </span><span class=\"not-connected-tooltip tooltipped tooltipped-w\" aria-label=\"Disconnected\"><span class=\"not-connected-icon\"></span> </span><span class=\"badge"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.highlight : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-highlight=\""
    + alias2(alias1((depth0 != null ? depth0.highlight : depth0), depth0))
    + "\">"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.unread : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span></div><span class=\"add-channel-tooltip tooltipped tooltipped-w tooltipped-no-touch\" aria-label=\"Join a channel…\" data-alt-label=\"Cancel\"><button class=\"add-channel\" aria-label=\"Join a channel…\" aria-controls=\"join-channel-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\"></button> </span> ";
},"3":function(container,depth0,helpers,partials,data) {
    return " highlight";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/roundBadgeNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.unread : depth0),{"name":"roundBadgeNumber","hash":{},"data":data}));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return " <span class=\"name\" title=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</span><span class=\"badge"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.highlight : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-highlight=\""
    + alias2(alias1((depth0 != null ? depth0.highlight : depth0), depth0))
    + "\">"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.unread : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span><span class=\"close-tooltip tooltipped tooltipped-w\" aria-label=\"Leave\"><button class=\"close\" aria-label=\"Leave\"></button> </span> ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("/home/travis/build/thelounge/thelounge/client/views/join_channel.tpl"),depth0,{"name":"join_channel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});